import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "src/components/layout";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import Query from "../../components/utils/utils";
import {
  GeneratePage,
  getDescription,
  getPageHeader,
  getTitle,
} from "../../components/utils/utils";

const searchString = "end-of-the-game";
const EndOfTheGame = () => {
  const setIsSideMenuOpen = sideMenuStore((state) => state.setIsSideMenuOpen);

  const [apiData, setApiData] = useState(Query);
  return (
    <>
      <Helmet>
        <title>{getTitle(apiData, searchString)}</title>
        <meta
          name='description'
          content={getDescription(apiData, searchString)}
        />
      </Helmet>
      <Layout
        html={GeneratePage(apiData, searchString)}
        header={getPageHeader(
          apiData,
          searchString
        ).pageTitleLine1?.toUpperCase()}
        header2={getPageHeader(
          apiData,
          searchString
        ).pageTitleLine2?.toUpperCase()}>
        <div className='z-10 mx-auto flex flex-col justify-center flex-wrap h-full text-brightYellow'>
          <button
            type='button'
            onClick={() => setIsSideMenuOpen(true)}
            className='mb-12 xl:mb-20'></button>
          <div className='relative'></div>
        </div>
      </Layout>
    </>
  );
};

export default EndOfTheGame;
